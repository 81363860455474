/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import Headline from '../../components/Headline';
import {Container, Section} from '../../layout/Grid';
import {parseHtmlToReact} from '../../../utils/html-react-parser';
import {contextToStringParser} from '../../../utils/content-html-parser';
import LocationContext from '../../../ui/utils/LocationContext';

import {routes} from '../../../model/navigation/routes';
import {scrollToAnchorStart} from '../../../utils/scroll-utils';
export default function Legal({data}) {
    const {content, slug, title} = data;
    const stringContent = contextToStringParser(content);

    const location = useContext(LocationContext);
    useEffect(() => {
        if (location && location.hash === '#teilnahmebedingungen') {
            scrollToAnchorStart('teilnahmebedingungen');
        }
    }, [location]);

    useEffect(() => {
        if (`/${slug}` === routes.datenschutz) {
            const script = document.createElement('script');
            script.src =
                'https://consent.cookiebot.com/ec6e814c-760e-4638-9c03-6adf75243bee/cd.js';
            script.async = true;
            script.type = 'text/javascript';
            document.getElementById('cookie-declaration').appendChild(script);
        }
    }, []);

    return (
        <Section>
            <Container width="narrow">
                <Headline type="h1" id={slug}>
                    {title}
                </Headline>
                {parseHtmlToReact(stringContent)}
                <div
                    id="cookie-declaration"
                    data-scroll-anchor="cookie-declaration"
                />
            </Container>
        </Section>
    );
}

Legal.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.array,
        slug: PropTypes.string,
        title: PropTypes.string,
    }),
};
