export const contextToStringParser = (content) => {
    const stringContent = content.reduce((total, currentValue) => {
        if (currentValue.type === 'anchor') {
            return (
                total +
                `<div id="${currentValue.anchor_id}" data-scroll-anchor="${currentValue.anchor_id}"></div>`
            );
        }
        return total + currentValue.text;
    }, '');
    return stringContent;
};
