import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {selectById as selectLegalPagesById} from '../../model/legal-pages';

import Layout from '../layout/Layout';
import Legal from '../domains/legal';

export default function StaticPage({pageContext}) {
    const {id} = pageContext;
    const legalPagesById = useSelector(selectLegalPagesById);
    const legalPageData = legalPagesById[id];
    return (
        <Layout>
            {legalPageData ? (
                <Legal data={legalPageData} />
            ) : (
                <p>Keine Inhalte</p>
            )}
        </Layout>
    );
}

StaticPage.propTypes = {
    pageContext: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};
